import React from 'react'

export default class Render extends React.Component {
  state = {
    content: ''
  }
  componentDidMount () {
    var editor = window.cledit(
      document.querySelector('.content'),
      // Optional (pass a second arg if scrollbar is not on the first arg)
      document.querySelector('.scroller')
    )
    editor.on('contentChanged', (content, diffs, sectionList) => {
      this.setState({content}, () => this.props.onChange(content))
});
    let imgEltsToCache = []
    editor.highlighter.on('sectionHighlighted', (section) => {
            section.elt.getElementsByClassName('token img').cl_each((imgTokenElt) => {
              const srcElt = imgTokenElt.querySelector('.token.cl-src');
              if (srcElt) {
                // Create an img element before the .img.token and wrap both elements
                // into a .token.img-wrapper
                const imgElt = document.createElement('img');
                imgElt.style.display = 'none';
                const uri = srcElt.textContent;
                // if (!/^unsafe/.test(htmlSanitizer.sanitizeUri(uri, true))) {
                  imgElt.onload = () => {
                    imgElt.style.display = '';
                  };
                  imgElt.src = uri;
                  // Take img size into account
                  const sizeElt = imgTokenElt.querySelector('.token.cl-size');
                  if (sizeElt) {
                    const match = sizeElt.textContent.match(/=(\d*)x(\d*)/);
                    if (match[1]) {
                      imgElt.width = parseInt(match[1], 10);
                    }
                    if (match[2]) {
                      imgElt.height = parseInt(match[2], 10);
                    }
                  }
                  imgEltsToCache.push(imgElt);
                // }
                const imgTokenWrapper = document.createElement('span');
                imgTokenWrapper.className = 'token img-wrapper';
                imgTokenElt.parentNode.insertBefore(imgTokenWrapper, imgTokenElt);
                imgTokenWrapper.appendChild(imgElt);
                imgTokenWrapper.appendChild(imgTokenElt);
              }
            });
    });
    var prismGrammar = window.mdGrammar({
      fences: true,
      tables: true,
      footnotes: true,
      abbrs: true,
      deflists: true,
      tocs: true,
      dels: true,
      subs: true,
      sups: true
    })
    editor.init({
      sectionHighlighter: function (section) {
        return window.Prism.highlight(section.text, prismGrammar)
      },
      // Optional (increases performance on large documents)
      sectionParser: function (text) {
        var offset = 0
        var sectionList = []
        ;(text + '\n\n').replace(/^.+[ \t]*\n=+[ \t]*\n+|^.+[ \t]*\n-+[ \t]*\n+|^\#{1,6}[ \t]*.+?[ \t]*\#*\n+/gm, function (match, matchOffset) {
          sectionList.push(text.substring(offset, matchOffset))
          offset = matchOffset
        })
        sectionList.push(text.substring(offset))
        return sectionList
      }
    })

  }
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.state.content
  }

  render () {
    return <div className="scroller">
        <pre className="content" dangerouslySetInnerHTML={{__html: this.props.value}}>
        </pre>
      </div>
  }
}
