import React, { Component } from 'react'
import {Route} from 'react-router-dom'
import {StorworkInit} from 'storwork-stores'
import {observer} from 'mobx-react'
import getStore from './Store'
import Render from './Render'
import './App.css';

let timeout = false

const Application = observer(class _Application extends Component {
  state = {
    saved: false
  };

  render() {
    return (
      <div>
      <Render onChange={val => {
        if (timeout) clearTimeout(timeout)
        if (val === this.props.store.data.content) return
        this.props.store.data.content = val
        timeout = setTimeout(() => {
          this.props.store.sync()
          this.setState({saved: timeout});
        }, 3000)
        this.setState({saved: false});
      }} value={this.props.store.data.content}/>

      <div style={{color: 'rgba(76,209,55)', bottom: 0, position: 'absolute', left: 10, display: (this.state.saved ? 'block' : 'none')}}>
        <i className={'4x fa fa-save'} />
      </div>
      </div>
    )
  }
})

const ApplicationWithStore = props => <Application store={getStore(props.match.params.id)} />

class App extends Component {
  render() {
    return <StorworkInit loggedIn={
      <Route exact path='/:id' component={ApplicationWithStore} />
    } />
  }
}

export default App;
