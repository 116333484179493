import client from 'socket.io-client'
import { decorate, observable } from 'mobx'
import { JWT } from 'storwork-stores'

let store = null
export default (id) => {
  if (!store) store = new Store(id)
  return store
}

const Store = decorate(class _Store {
  data = null
  socket = null
  query = null
  loaded = false
  responce = null

  setQuery = query => {
    this.query = query
    this.socket.emit('newQuery', this.query)
  }
  sync = () => {
    console.log('sync')
    this.responce.search[0].json = JSON.stringify(this.data)
    this.socket.emit('sync', this.responce)
  }
  getData = data => {
    console.log(data);
    if (data.search[0] && data.search[0].type === "markdown"){
      this.responce = data
      this.data = JSON.parse(data.search[0].json)
      this.loaded = true
    }
  }
  initSocket = (query) => {
    const socket = client('https://mega.storwork.pl', {
      query: {
        authorization: "Bearer " + JWT.get()
      }
    })
    socket.on('connect', () => {
      console.log('Connected')
      this.socket = socket
      this.socket.on('disconnect', function(){
        console.log('Disconnected')
      })
      this.socket.on('newDataLoaded', this.getData)
      if (query) {
        console.log('Initial query set')
        this.setQuery(query)
      }
    })
  }
  constructor() {
    const id = 214
    const query = `{
      search (id:${id}, type:"generalobject") {
        ... on GeneralObject {
          id
          json
          type
          indexType
        }
      }
    }`
    this.initSocket(query)
    this.data = {content: ''}
  }
}, {
  data: observable,
  query: observable,
  loaded: observable
})
